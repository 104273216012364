import React, { useEffect, useState } from 'react'
import { Button, Input, Spinner } from 'reactstrap'
import { navigate } from 'gatsby'
import { useDispatch, useSelector } from 'react-redux'

import AuthenticationService from '../services/authentication'
import Background from '../components/common/Background'
import EmptyLayout from '../components/layouts/EmptyLayout'
import { EnterprisesState, enterprisesSelector, fetchEnterpriseAction } from '../hasura/slices/enterprises'
import { Role } from '../interfaces/Role'
import { searchQueryParams, validEmail } from '../lib/helpers'
import { setAccessTokenAction } from '../hasura/slices/users'

// @ts-ignore
import poweredBy from '../lib/images/poweredbyradimal.png'

enum PageState {
  Login,
  SignUp,
}

export default function Enterprise() {
  const dispatch = useDispatch()

  const [email, setEmail] = useState<string | undefined>() // ('otplunkett+stub9292@gmail.com')
  const [error, setError] = useState<string | undefined>()
  const [password, setPassword] = useState<string | undefined>() // ('password123')
  const [role, setRole] = useState<Role>(Role.User)
  const [pageState, setPageState] = useState<PageState>(PageState.SignUp)
  const [isNetworking, setIsNetworking] = useState(false)

  const isSignUp = pageState === PageState.SignUp

  const { enterprise }: EnterprisesState = useSelector(enterprisesSelector)

  useEffect(() => {
    setError(undefined)
  }, [email, password, pageState])

  useEffect(() => {
    if (searchQueryParams('r') === 'vet') setRole(Role.Vet)
  }, [])

  useEffect(() => {
    const enterpriseId = parseInt(searchQueryParams('i') || '', 10)
    if (!enterpriseId) return

    dispatch(fetchEnterpriseAction(enterpriseId))
  }, [])

  const accessTokenCallback = (accessToken?: string, error?: any) => {
    if (accessToken) {
      dispatch(setAccessTokenAction(accessToken))
      navigate('/cases')
    } else if (error) {
      setError(error)
    }
    setIsNetworking(false)
  }

  const handleContinue = (e: any) => {
    e.preventDefault()
    setIsNetworking(true)
    if (!email || !password || !enterprise) return

    if (isSignUp) {
      new AuthenticationService().signUp(email, password, role, enterprise.id, enterprise.default_price_group_id, accessTokenCallback)
    } else {
      new AuthenticationService().logIn(email, password, accessTokenCallback)
    }
  }

  if (!enterprise) {
    return (
      <EmptyLayout>
        <Background>
          <Spinner size="lg" color="primary" />
        </Background>
      </EmptyLayout>
    )
  }

  return (
    <EmptyLayout>
      <Background light>
        <div className="width-350px text-center rounded p-4 shadow">
          {enterprise.logo_aws_s3_url && <img src={enterprise.logo_aws_s3_url} className="width-150px" />}

          <p className="text-xl my-3">Welcome{role === Role.Vet ? ' Specialist' : ''}</p>

          <p className="text-m mb-4">
            {isSignUp ? 'Sign up' : 'Log in'} to <span className="bold">{enterprise?.short_name}</span> and continue to Your Dashboard.
          </p>

          <form onSubmit={handleContinue}>
            <div className="mb-2 text-left">
              <p className="m-0 text-s">Email address</p>
              <Input
                placeholder="Email address"
                style={{ height: '50px' }}
                autoFocus
                type="text"
                value={email}
                onChange={(e: any) => setEmail(e.target.value)}
              />
            </div>

            <div className="text-left">
              <p className="m-0 text-s">Password</p>
              <Input
                placeholder="Password"
                style={{ height: '50px' }}
                type="password"
                value={password}
                onChange={(e: any) => setPassword(e.target.value)}
              />
            </div>

            <p className={`text--danger mt-2 mb-0 bold text-s`}>{error || ''}</p>

            <Button
              className="w-100 mt-3"
              color="primary"
              disabled={!email || !validEmail(email) || !password || isNetworking}
              size="lg"
              type="submit"
            >
              {isNetworking ? <Spinner size="sm" className="primary" /> : 'Continue'}
            </Button>
          </form>

          <p className="m-0 text-left mt-3 text-m">
            {isSignUp ? 'Already have an account?' : "Don't have an account?"}
            <span
              onClick={() => setPageState(isSignUp ? PageState.Login : PageState.SignUp)}
              className="bold ml-2 text--secondary6 pointer"
            >
              {isSignUp ? 'Log in' : 'Sign up'}
            </span>
          </p>

          <img className="width-150px mt-3" src={poweredBy} />
        </div>
      </Background>
    </EmptyLayout>
  )
}

import auth0 from 'auth0-js'
import compact from 'lodash/compact'

import CONFIG from '../config'
import { Role } from '../interfaces/Role'

const humanReadableError = (error: any) => {
  if (error.code === 'user_exists') {
    return 'User already exists.'
  } else if (error.code === 'invalid_password') {
    return 'Invalid password. Must be at least 8 characters.'
  } else if (error.code === 'invalid_grant') {
    return 'Wrong email or password.'
  } else {
    return 'Sign up failed.'
  }
}

export default class AuthenticationService {
  auth: auth0.WebAuth
  dbConnection = 'Username-Password-Authentication'
  responseType = 'token id_token'
  scope = 'read:order write:order'
  dbCookieKey = 'access_token'

  constructor() {
    this.auth = new auth0.WebAuth({
      audience: CONFIG.AUTH0_AUDIENCE,
      clientID: CONFIG.AUTH0_CLIENT_ID!,
      domain: CONFIG.AUTH0_DOMAIN!,
      responseType: this.responseType,
      scope: this.scope,
    })
  }

  checkSession(cb: (accessToken?: string) => void) {
    const accessToken = this.getAccessTokenCookie()
    return cb(accessToken)
  }

  deleteAccessTokenCookie() {
    const cookieString = `${this.dbCookieKey}=; ${window.location.host}; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`
    console.log('deleting access token cookie...')
    document.cookie = cookieString
  }

  getAccessTokenCookie(): string | undefined {
    const value = `; ${document.cookie}`
    const parts = compact(value.split(`; access_token=`))

    if (parts.length === 2) {
      const accessToken = parts.pop()!.split(';').shift()
      console.log('found access token cookie')
      return accessToken
    }
  }

  logIn(email: string, password: string, cb: (accessToken?: string, error?: string) => void) {
    const params = { username: email, password: password.trim(), realm: this.dbConnection }

    this.auth.client.login(params, (error: any, result: any) => {
      if (error) {
        console.log(`ERROR signup: ${JSON.stringify(error)}`)
        cb(undefined, humanReadableError(error))
      } else {
        const accessToken = result.accessToken
        this.setAccessTokenCookie(accessToken)
        cb(accessToken)
      }
    })
  }

  setAccessTokenCookie(accessToken: string) {
    const expiresInHours = 72
    const expirationDate = new Date()
    expirationDate.setHours(expirationDate.getHours() + expiresInHours)
    const cookieString = `${this.dbCookieKey}=${accessToken}; ${window.location.host}; expires=${expirationDate.toUTCString()}`
    console.log('creating cookie...')
    document.cookie = cookieString
  }

  signUp(
    email: string,
    password: string,
    role: Role,
    enterprise_id: number,
    price_group_id: number | null,
    cb: (accessToken?: string, error?: string) => void
  ) {
    const userMetadata: any = {
      sign_up_enterprise_id: String(enterprise_id),
      sign_up_price_group_id: String(price_group_id),
      sign_up_role: role,
    }
    const params: any = { email, password: password.trim(), connection: this.dbConnection, userMetadata }
    console.log(`signup with params: ${JSON.stringify(params)}`)

    this.auth.signupAndAuthorize(params, (error: any, result: any) => {
      if (error) {
        console.log(`ERROR signup: ${JSON.stringify(error)}`)
        cb(undefined, humanReadableError(error))
      } else {
        const accessToken = result.accessToken
        this.setAccessTokenCookie(accessToken)
        cb(accessToken)
      }
    })
  }
}

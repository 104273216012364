import styled from 'styled-components'

import FlexBox from './FlexBox'
import { palette } from '../../lib/cssHelpers'
import { shade } from '../../lib/helpers'

interface Props {
  dark?: boolean
  light?: boolean
}

export default styled(FlexBox)`
  position: fixed;
  height: 100vh;
  width: 100vw;
  z-index: -9999;
  flex-direction: column;
  background-image: ${(props: Props) =>
    props.dark || props.light
      ? ''
      : `linear-gradient(
    to bottom,
    ${shade(palette.secondary, 0.98)},
    ${shade(palette.secondary, 0.92)}
  )`};
  background-color: ${(props: Props) => (props.dark ? 'black' : '')};
  transition: 0.2s;
`
